'use client';

import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { EnumType } from '../components/_shared/AlertHandler';

export interface LayoutStoreType {
	AlertValues: AlertType[];
	Alert(arg0: string, arg1?: EnumType): void;
	resetAlert(): void;
	removeAlert(id: string): void;
	authRedirect: string | null;
	setAuthRedirect(arg0: string | null): void;
	topBannerActive: boolean;
	setTopBannerActive(arg0: boolean): void;
}

export interface AlertType {
	id: string;
	show: boolean;
	message: string;
	type?: EnumType;
}

const LayoutStore = (): LayoutStoreType => {
	const [AlertValues, setAlertValues] = useState<AlertType[]>([]);
	const [authRedirect, setAuthRedirect] = useState<string | null>(null);
	const [topBannerActive, setTopBannerActive] = useState<boolean>(false);

	const resetAlert = () => setAlertValues([]);

	const Alert = (message: string, type?: EnumType) => {
		setAlertValues((prev) => [
			...prev,
			{
				show: true,
				message,
				type,
				id: uuid(),
			},
		]);
	};

	const removeAlert = (id: string) => {
		const alerts = [...AlertValues];
		const index = alerts.findIndex((alert) => alert.id === id);
		if (index !== -1) {
			alerts[index].show = false;
			setAlertValues(alerts);
		}
	};

	return {
		AlertValues,
		Alert,
		resetAlert,
		removeAlert,
		authRedirect,
		setAuthRedirect,
		topBannerActive,
		setTopBannerActive,
	};
};

export default LayoutStore;
