'use client';

import React from 'react';

import upsell from '@monorepo/upsell-kit';

import ContextProvider from '../contexts';

const RootLayoutClient = ({ children }: { children: React.ReactNode }) => {
	upsell.init({
		platform: 'web',
		storeID: '653271b30ad81ca21fdc2bfd',
	});

	return <ContextProvider>{children}</ContextProvider>;
};

export default RootLayoutClient;
